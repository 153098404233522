@import '~@angular/material/theming';

@mixin anms-todos-container-component-theme($theme) {
  $accent: map-get($theme, accent);

  .todos-filter-menu-overlay {
    .active {
      color: mat-color($accent, default-contrast);
      background-color: mat-color($accent);

      .fa-icon {
        color: mat-color($accent, default-contrast);
      }

      &:hover {
        color: mat-color($accent, default-contrast);
        background-color: mat-color($accent);
      }
    }
  }

  .todos-notification-overlay {
    .mat-simple-snackbar {
      align-items: center;

      button {
        border-radius: 2px;
        background-color: mat-color($accent);
        color: mat-color($accent, default-contrast);
      }
    }
  }
}
