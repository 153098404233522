@import '~@angular/material/theming';

@font-face {
  font-family: 'VistaSans';
  //src: url('/assets/font/vistasansotce-med.woff2') format('woff2');
  //src: url('/assets/font/vistaslabotce-book.woff2') format('woff2');
  src: url('/assets/font/vistaslabotce-light.woff2') format('woff2');
}

@font-face {
  font-family: 'VistaSansMed';
  src: url('/assets/font/vistasansotce-med.woff2') format('woff2');
  //src: url('/assets/font/vistaslabotce-book.woff2') format('woff2');
  //src: url('/assets/font/vistaslabotce-light.woff2') format('woff2');
}

// @see https://material.angular.io/guide/typography
//$custom-typography: mat-typography-config(
$general-typography: mat-typography-config(
  $font-family: '"VistaSans", sans-serif',
);

body {
  font-family: VistaSans, Roboto, Arial, sans-serif;
}

.mat-bottom-sheet-container {
  font-family: VistaSans, Roboto, Arial, sans-serif;
}

.mat-list-item {
  font-family: VistaSansMed, sans-serif;
}
mat-card-title {
  font-family: VistaSansMed, sans-serif;
}
p {
  color: #052f43;
}
