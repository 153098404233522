@import '~@angular/material/theming';

@mixin anms-parent-component-theme($theme) {
  $accent: map-get($theme, accent);

  anms-parent {
    > .container {
      > .row {
        > .col-md-6 {
          > .example {
            border-color: mat-color($accent);

            > h1 {
              color: mat-color($accent);
            }
          }
        }
      }
    }
  }
}
